import React, { useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Container, Typography, Tooltip as ToolTipDot } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import CircleIcon from "@mui/icons-material/Circle";
import { ScatterPlot } from "./ScatterGraph";
import { getSurveyInsightsDetails } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DoubleMaterialityScatterPlot from "./DoubleMaterialityScatterPlot";

const InsightsDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const surveyDetails = useSelector(
    (state) => state.materiality.getSurveyInsightsDetails.data
  );

  useEffect(() => {
    dispatch(getSurveyInsightsDetails(id));
  }, [dispatch]);

  const categorized = {};

  for (const mainKey in surveyDetails) {
    if (!categorized[mainKey])
      categorized[mainKey] = {
        high: [],
        emerging: [],
        low: [],
      };
    const subObject = surveyDetails[mainKey];
    for (const subKey in subObject) {
      const value = subObject[subKey];
      if (value >= 4 && value <= 5) {
        categorized[mainKey]["high"].push(subKey);
      } else if (value >= 3 && value < 4) {
        categorized[mainKey]["emerging"].push(subKey);
      } else if (value >= 1 && value < 3) {
        categorized[mainKey]["low"].push(subKey);
      }
    }
  }

  const recommendationMap = {
    high: [
      "Allocate significant resources to address these areas immediately.",
      "Implement scalable, measurable solutions to create tangible outcomes.",
      "Ensure continuous monitoring and reporting for stakeholders.",
    ],
    emerging: [
      "Gradually integrate these topics into strategic plans.",
      "Focus on improving transparency, engagement, and ethical practices.",
      "Monitor trends and adapt initiatives to increase relevance over time.",
    ],
    low: [
      "Maintain awareness and incorporate them into long-term goals.",
      "Educate stakeholders about their potential future relevance.",
      "Reassess periodically as priorities and conditions evolve.",
    ],
  };

  const overviewRecoMap = [
    "Actively collaborate with stakeholders to align priorities and gather feedback for continuous improvement.",
    "Use analytics and measurable metrics to prioritize, monitor, and refine initiatives effectively.",
    "Embed sustainability into all actions and ensure adherence to regulations and industry standards.",
    "Communicate progress regularly with clear and standardized reporting to build trust and accountability.",
    "Develop adaptable solutions that can scale with organizational growth and adjust to evolving trends.",
  ];

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        {categorized && Object.keys(categorized).length > 0 ? (
          <>
            <Typography className={classes.headings} variant="h5">
              MATERIALITY MATRIX
            </Typography>
            <div className={classes.content}>
              <div className={classes.commonChart}>
                <ScatterPlot
                  dataMap={surveyDetails}
                  xAttr={"Impact of Company"}
                  yAttr={"Importance to Stakeholders"}
                  xAxisLabel={"Impact of Company"}
                  yAxisLabel={"Importance to Stakeholders"}
                />
              </div>
              <div className={classes.commonSuggestion}>
                <Typography className={classes.headings} variant="h6">
                  Materiality Matrix Summary: Key Insights & Recommendations
                </Typography>
                <Typography className={classes.mainContentText}>
                  1. Top Priorities:
                </Typography>
                {categorized["Impact of Company"]["high"].length ||
                categorized["Importance to Stakeholders"]["high"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Impact of Company"]?.high.length
                        ? "Impact of Company " +
                          categorized["Impact of Company"]?.high.join(", ") +
                          ". "
                        : ""}
                      {categorized["Importance to Stakeholders"]?.high.length
                        ? "Importance to Stakeholders " +
                          categorized["Importance to Stakeholders"]?.high.join(
                            ", "
                          )
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["high"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <Typography className={classes.mainContentText}>
                  2. Emerging Priorities:
                </Typography>
                {categorized["Impact of Company"]["emerging"].length ||
                categorized["Importance to Stakeholders"]["emerging"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Impact of Company"]?.emerging.length
                        ? "Impact of Company " +
                          categorized["Impact of Company"]?.emerging.join(
                            ", "
                          ) +
                          ". "
                        : ""}
                      {categorized["Importance to Stakeholders"]?.emerging
                        .length
                        ? "Importance to Stakeholders " +
                          categorized[
                            "Importance to Stakeholders"
                          ]?.emerging.join(", ")
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["emerging"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <Typography className={classes.mainContentText}>
                  3. Lower Priorities:
                </Typography>
                {categorized["Impact of Company"]["low"].length ||
                categorized["Importance to Stakeholders"]["low"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Impact of Company"]?.low.length
                        ? "Impact of Company " +
                          categorized["Impact of Company"]?.low.join(", ") +
                          ". "
                        : ""}
                      {categorized["Importance to Stakeholders"]?.low.length
                        ? "Importance to Stakeholders " +
                          categorized["Importance to Stakeholders"]?.low.join(
                            ", "
                          )
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["low"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <span className={classes.overAllSuggesion}>
                  <Typography className={classes.mainContentText}>
                    Overall:{" "}
                  </Typography>{" "}
                  <Typography>
                    {
                      overviewRecoMap[
                        Math.floor(Math.random() * overviewRecoMap.length)
                      ]
                    }
                  </Typography>
                </span>
              </div>
              <Typography className={classes.headings} variant="h5">
                DOUBLE MATERIALITY MATRIX
              </Typography>
              <div className={classes.commonChart}>
                <ScatterPlot
                  dataMap={surveyDetails}
                  xAttr={"Financial Materiality"}
                  yAttr={"Impact Materiality"}
                  xAxisLabel={"Financial Materiality"}
                  yAxisLabel={"Impact Materiality"}
                />
              </div>
              <div className={classes.commonSuggestion}>
                <Typography className={classes.headings} variant="h6">
                  Double Materiality Matrix Summary: Key Insights &
                  Recommendations
                </Typography>
                <Typography className={classes.mainContentText}>
                  1. Top Priorities:
                </Typography>
                {categorized["Financial Materiality"]["high"].length ||
                categorized["Impact Materiality"]["high"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Financial Materiality"]?.high.length
                        ? "Financial Materiality " +
                          categorized["Financial Materiality"]?.high.join(
                            ", "
                          ) +
                          ". "
                        : ""}
                      {categorized["Impact Materiality"]?.high.length
                        ? "Impact Materiality " +
                          categorized["Impact Materiality"]?.high.join(", ")
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["high"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <Typography className={classes.mainContentText}>
                  2. Emerging Priorities:
                </Typography>
                {categorized["Financial Materiality"]["emerging"].length ||
                categorized["Impact Materiality"]["emerging"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Financial Materiality"]?.emerging.length
                        ? "Financial Materiality " +
                          categorized["Financial Materiality"]?.emerging.join(
                            ", "
                          ) +
                          ". "
                        : ""}
                      {categorized["Impact Materiality"]?.emerging.length
                        ? "Impact Materiality " +
                          categorized["Impact Materiality"]?.emerging.join(", ")
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["emerging"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <Typography className={classes.mainContentText}>
                  3. Lower Priorities:
                </Typography>
                {categorized["Financial Materiality"]["low"].length ||
                categorized["Impact Materiality"]["low"].length ? (
                  <>
                    <Typography className={classes.suggesionContentTextTopic}>
                      {categorized["Financial Materiality"]?.low.length
                        ? "Financial Materiality " +
                          categorized["Financial Materiality"]?.low.join(", ") +
                          ". "
                        : ""}
                      {categorized["Impact Materiality"]?.low.length
                        ? "Impact Materiality " +
                          categorized["Impact Materiality"]?.low.join(", ")
                        : ""}
                    </Typography>
                    <span className={classes.subtopicTitle}>
                      <ToolTipDot
                        title={`test`}
                        classes={{ popper: classes.tooltip }}
                        arrow
                        placement="left-start"
                      >
                        <CircleIcon className={classes.infoIcon} />
                      </ToolTipDot>
                      <Typography className={classes.suggesionContentText}>
                        {
                          recommendationMap["low"][
                            Math.floor(
                              Math.random() * recommendationMap["high"].length
                            )
                          ]
                        }
                      </Typography>
                    </span>
                  </>
                ) : (
                  <Typography className={classes.suggesionContentText}>
                    No suggestion
                  </Typography>
                )}
                <span className={classes.overAllSuggesion}>
                  <Typography className={classes.mainContentText}>
                    Overall:{" "}
                  </Typography>{" "}
                  <Typography>
                    {
                      overviewRecoMap[
                        Math.floor(Math.random() * overviewRecoMap.length)
                      ]
                    }
                  </Typography>
                </span>
              </div>
              {/* Render DoubleMaterialityScatterPlot as the third chart */}
              <Typography className={classes.headings} variant="h5">
                DOUBLE MATERIALITY SCATTER PLOT
              </Typography>
              <div className={classes.commonChart}>
              <Typography style={{fontSize:18, marginLeft: "12vw", fontWeight:500}} >
              Double Materiality Scatter Plot - Iron & Steel Industry
              </Typography>
                <DoubleMaterialityScatterPlot
                  financialMateriality={surveyDetails["Financial Materiality"]}
                  impactMateriality={surveyDetails["Impact Materiality"]}
                />
              </div>
              <div className={classes.commonChart}>
                <Typography  style={{fontSize: '18px', marginBottom: '36px'}}>
                Each point represents a topic, with its position based on Financial Materiality (X-axis)
                & Impact Materiality (Y-axis) scores.
                </Typography>
                <Typography className={classes.suggesionContentTextTopic}>
                1. High Financial & High Impact Materiality (Top Right)
                </Typography><Typography className={classes.suggesionContentTextTopic}>
                2. High Financial & Low Impact Materiality (Bottom Right)
                </Typography><Typography className={classes.suggesionContentTextTopic}>
                3. Low Financial & High Impact Materiality (Top Left)
                </Typography><Typography className={classes.suggesionContentTextTopic}>
                4. Low Financial & Low Impact Materiality (Bottom Left)
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <Typography className={classes.headings} variant="h6">
            MATERIALITY MATRIX IS NOT AVAILABLE
          </Typography>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default InsightsDetails;