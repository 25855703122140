import { Box, Container, Grid, Modal, Paper } from "@mui/material";
import useStyles from "./styles";
import CeroItemPair from "../../../../../components/CeroItemPair";
import PdfFileIconWithDownload from "../../../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const Status = (props) => {
  const classes = useStyles();
  const initiativeDetails = props.details;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfPopup = () => {
    return (
      <embed
        src={`${initiativeDetails.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="525px"
      />
    );
  };

  return (
    <Container className={classes.statusContainer}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CeroItemPair
            title="Mission:"
            value={initiativeDetails.mission}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Initiative:"
            value={
              initiativeDetails.initiative ? initiativeDetails.initiative : "NA"
            }
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Initiative ID:"
            value={
              initiativeDetails.initiative_id
                ? initiativeDetails.initiative_id
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Start year:"
            value={initiativeDetails.year ? initiativeDetails.year : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
        </Grid>
        <Grid item xs={4}>
          <CeroItemPair
            title=""
            value=""
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Country:"
            value={initiativeDetails.country ? initiativeDetails.country : "NA"}
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Facility:"
            value={
              initiativeDetails.facility_name
                ? initiativeDetails.facility_name
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          <CeroItemPair
            title="Tracking Cycle:"
            value={
              initiativeDetails.tracking_cycle
                ? initiativeDetails.tracking_cycle
                : "NA"
            }
            classes={{ container: classes.infoContainer, title: classes.title }}
          />
          {initiativeDetails.blob_url && (
            <Grid>
              <Paper
                style={{
                  padding: 0,
                  boxShadow: "none",
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  right: 40,
                  top: 65,
                  position: "absolute",
                  cursor: "pointer", 
                }}
                onClick={handlePdfOpen} 
              >
                <div
                  style={{
                    transform: "scale(0.5)",
                    width: "160px",
                    height: "120px",
                    background: "#d9dadb",
                  }}
                >
                  <PdfFileIconWithDownload />
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default Status;