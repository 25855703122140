import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  ReferenceArea,
  LabelList,
} from 'recharts';

const DoubleMaterialityScatterPlot = ({ financialMateriality, impactMateriality }) => {
  const data = Object.keys(financialMateriality).map((key) => ({
    x: financialMateriality[key],
    y: impactMateriality[key],
    name: key,
    category: key,
  }));

  const colors = {
    Environment: 'green',
    'Human Capital': 'blue',
    'Business Model and Innovation': 'orange',
  };

  const customShapes = {
    Environment: (props) => (
      <path d="M5 0 L10 5 L5 10 L0 5 Z" fill={props.fill} />
    ),
    'Human Capital': (props) => (
      <rect x="0" y="0" width="10" height="10" fill={props.fill} />
    ),
    'Business Model and Innovation': (props) => (
      <circle cx="5" cy="5" r="5" fill={props.fill} />
    ),
  };

  const boxColors = [
    ["#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f3dbda"],
    ["#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f3dbda"],
    ["#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda"], 
    ["#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda"], 
    ["#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda"], 
  ];

  const createBackgroundAreas = () => {
    const areas = [];
    const xStep = 5 / 5;
    const yStep = 5 / 5; 

    boxColors.forEach((row, rowIndex) => {
      row.forEach((color, colIndex) => {
        areas.push({
          x1: colIndex * xStep,
          x2: (colIndex + 1) * xStep,
          y1: rowIndex * yStep,
          y2: (rowIndex + 1) * yStep,
          fill: color,
        });
      });
    });

    return areas;
  };

  const backgroundAreas = createBackgroundAreas();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ background: '#fff', padding: 5, borderRadius: 5, boxShadow: '0 0 5px rgba(0,0,0,0.1)' }}>
          <p>{payload[0].payload.name}</p>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' }}>
      {payload.map((entry, index) => {
        const ShapeComponent = customShapes[entry.value];
        return (
          <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <svg width="20" height="20">
              <ShapeComponent fill={entry.color} />
            </svg>
            <span style={{ marginLeft: '10px' }}>{entry.value}</span>
          </div>
        );
      })}
    </div>
  );

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 50, left: 50 }}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        
        {backgroundAreas.map((area, index) => (
          <ReferenceArea
          key={index}
          x1={area.x1}
            x2={area.x2}
            y1={area.y1}
            y2={area.y2}
            fill={area.fill}
            opacity={1}
            fillOpacity={1}
            />
          ))}

          <CartesianGrid stroke="#ccc" strokeWidth={1} />

        <ReferenceLine x={2.5} stroke="black" strokeWidth={1} />
        <ReferenceLine y={2.5} stroke="black" strokeWidth={1} />

        <XAxis
          type="number"
          dataKey="x"
          domain={[0, 5]}
          tickCount={11}
          tick={{ fontSize: 12 }}
          interval={0} 
        >
          <Label value="Financial Materiality" position="bottom" />
        </XAxis>
        <YAxis
          type="number"
          dataKey="y"
          domain={[0, 5]}
          tickCount={11}
          tick={{ fontSize: 12 }}
          interval={0} 
        >
          <Label value="Impact Materiality" angle={-90} position="left" />
        </YAxis>

        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={<CustomLegend />}
          layout="vertical" 
          align="right" 
          verticalAlign="middle" 
          wrapperStyle={{ right: 0, top: '50%', transform: 'translateY(-50%)' }}
        />

        {Object.keys(colors).map((key, index) => (
          <Scatter
            key={index}
            name={key}
            data={data.filter((d) => d.category === key)}
            fill={colors[key]}
            shape={(props) => {
              const ShapeComponent = customShapes[key];
              return (
                <g transform={`translate(${props.cx},${props.cy})`}>
                  <ShapeComponent {...props} />
                </g>
              );
            }}
          >
            <LabelList
              dataKey="name" 
              position="bottom" 
              offset={10} 
              fill="black" 
              fontSize={12} 
            />
          </Scatter>
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default DoubleMaterialityScatterPlot;